import { React, useMemo, useState,useEffect } from "react";
import { useAsyncDebounce } from "react-table";
import { Label, Input } from "reactstrap";
import {OverlayTrigger, Tooltip} from 'react-bootstrap';
import Screeningtable from './ScreeningSummary';


// Component for Global Filter
export function GlobalFilter({ Externalvalue,onGlobalFilterChange,preFilteredRows, globalFilter, setGlobalFilter }) {
    const [value, setValue] = useState(globalFilter);
    // const count = preFilteredRows.length
    const onChange = useAsyncDebounce((value) => {
        setGlobalFilter(value || undefined);
    }, 200);
    useEffect(() => {
        onGlobalFilterChange(value);
        setGlobalFilter(Externalvalue);
    }, [value, Externalvalue, onGlobalFilterChange]);

    function convertToIsoFormat(dateString) {
        const [day, month, year] = dateString.split('-');
        if (/^\d{1}-\d{1}-\d{4}$/.test(dateString)) {
            return `${year}-${('0' + month).slice(-2)}-${('0' + day).slice(-2)}`;
        } else {
            return `${year}-${(month).slice(-2)}-${(day).slice(-2)}`;
        }
    }

    const tooltip = (
        <Tooltip id={`search-tooltip`}>
            Enter a keyword or a date in the format DD/MM/YYYY to search through the results returned from the filter
        </Tooltip>
    );

    return (
        <div>
            <OverlayTrigger placement="bottom" overlay={tooltip}>
                <Input
                    value={value || ""}
                    onChange={(e) => {
                        setValue(e.target.value);
    
                        let searchValue = e.target.value;
    
                        // Convert day and month to '-' separated format
                        searchValue = searchValue.replace(/\//g, '-');
                      
                        if (/^\d{1,2}-\d{1,2}$/.test(searchValue)) {
                           
                            const [day, month, ...rest] = searchValue.split('-');
                            searchValue = `${month}-${day}${rest.length ? '-' + rest.join('-') : ''}`;
                        }
    
                        // Check if the input value matches the "D-MM-YYYY" format
                        if (/^\d{1,2}-\d{1,2}-\d{4}$/.test(searchValue)) {
                            // Convert the input date to "YYYY-MM-DD" format
                            searchValue = convertToIsoFormat(searchValue);
                        }
    
                        onChange(searchValue);
                    }}
                    placeholder="Live Search"
                    style={{
                        marginRight: "15px",
                        display: "inline",
                        width: "250px",
                    }}
                />
            </OverlayTrigger>
        </div>
    );
}

// Component for Default Column Filter
export function DefaultFilterForColumn({
    column: {
        filterValue,
        preFilteredRows,
        setFilter,
    },
}) {
    return (
        <Input
            value={filterValue || ""}
            onChange={(e) => {
                // Set undefined to remove the filter entirely
                setFilter(e.target.value || undefined);
            }}
            placeholder={``}
            style={{ marginTop: "10px", outerWidth: "2px" }}
        />
    );
}



export function HandleFilterChange(column, value) {
    const [filters, setFilters] = useState([]);
    setFilters(prevState => ({
        ...prevState,
        [column]: value,
    }));
    return (
        <handleApplyFilters filters={filters} />
    )
};

// Component for Custom Select Filter
export function SelectColumnFilter({
    column: { filterValue, setFilter, preFilteredRows, id },
}) {


    // Use preFilteredRows to calculate the options
    const options = useMemo(() => {
        const options = new Set();
        preFilteredRows.forEach((row) => {
            options.add(row.values[id]);
        });
        return [...options.values()];
    }, [id, preFilteredRows]);

    // UI for Multi-Select box
    return (
        <select
            value={filterValue}
            onChange={(e) => {
                setFilter(e.target.value || undefined);
                HandleFilterChange(id, e)
            }}
        >            <option value="">All</option>
            {options.map((option, i) => (
                <option key={i} value={option}>
                    {option}
                </option>
            ))}
        </select>
    );
}

export function dateBetweenFilterFn(rows, id, filterValues) {
    const sd = filterValues[0] ? new Date(filterValues[0]) : undefined;
    const ed = filterValues[1] ? new Date(filterValues[1]) : undefined;
    if (ed || sd) {
        return rows.filter((r) => {
            if (r.values[id] != null) {
                // format data
                var dateAndHour = r.values[id].split("T" || "");

                var [year, month, day] = dateAndHour[0].split("-");
                var date = [month, day, year].join("/");
                var hour = dateAndHour[1];
                var formattedData = date + " " + hour;


                const cellDate = new Date(formattedData);

                if (ed && sd) {
                    return cellDate >= sd && cellDate <= ed;
                } else if (sd) {
                    return cellDate >= sd;
                } else {
                    return cellDate <= ed;
                }
            }
        });
    } else {
        return rows;
    }
}

export function DateRangeColumnFilter({
    column: { filterValue = [], preFilteredRows, setFilter, id }
}) {
    const [min, max] = useMemo(() => {
        let min = preFilteredRows.length
            ? new Date(preFilteredRows[0].values[id])
            : new Date(0);
        let max = preFilteredRows.length
            ? new Date(preFilteredRows[0].values[id])
            : new Date(0);

        preFilteredRows.forEach((row) => {
            const rowDate = new Date(row.values[id]);

            min = rowDate <= min ? rowDate : min;
            max = rowDate >= max ? rowDate : max;
        });

        return [min, max];
    }, [id, preFilteredRows]);

    return (
        <div>
            <input
                //min={min.toISOString().slice(0, 10)}
                onChange={(e) => {
                    const val = e.target.value;
                    setFilter((old = []) => [val ? val : undefined, old[1]]);
                    HandleFilterChange(id, filterValue[0]);
                }}
                type="date"
                value={filterValue[0] || ""}
            />
            {" to "}
            <input
                //max={max.toISOString().slice(0, 10)}
                onChange={(e) => {
                    const val = e.target.value;
                    setFilter((old = []) => [
                        old[0],
                        val ? val.concat("T23:59:59.999Z") : undefined
                    ]);
                    HandleFilterChange(id, filterValue[0])
                }}
                type="date"
                value={filterValue[1]?.slice(0, 10) || ""}
            />
        </div>
    );
}
