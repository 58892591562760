export const intialfiltervalues = {
    Location: '',
    Distance:'',
    Status: '',
    Engagement: '',
    Profile: '',
    AssignedTo: '',
    Department1: '',
    Department2: '',
    Department3: '',
    AppliedFrom: '',
    AppliedTo: '',
    Company: '',
    Customdate:'',
    Referercode:'',
    companyname: true,
    assignedToName: true,
    //staffID: true,
    //lastname: true,
    //firstname: true,
    middlename: true,
    //profileName: true,
    referer: true,
    dep1: true,
    dep2: true,
    dep3: true,
    areaManagerName: true,
    engagementMethodDescription: true,
    post_Town: true,
    mobileNo: true,
    applicationDate: true,
    statusDescription: true,
    lastchangedate: true,
    screeningstarted: true,
    datedeployed: true,
    datelastworked: true,
    dayssincelastshift: true,
    docslastchased: true,
    refslastchased: true,
    prescreenstatus: true,
    refs: true,
    yearscovered: true,
    daysleft: true,
    Edit: true,
    searchTerm: '',
    defaultFilter: false
};
export const intialtwofiltervalues = {
    Profile: '',
    AssignedTo: '',
    Status:[]
}